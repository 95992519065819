import { IContent } from "@/types";
import { Card, Container, Skeleton, Text } from "@mantine/core";
import dayjs from "dayjs";
import Link from "next/link";

export function ColumnNews({ news }: { news: IContent[] }): JSX.Element {
  return (
    <Container
      size="xl"
      className="border border-solid border-dark-5 py-2 md:px-8"
    >
      {news
        ? news?.map((content, index, news) => (
            <Card
              key={index}
              className={`flex flex-col md:flex-row md:gap-6 transition-transform duration-300 
                          ease-in-out md:hover:scale-105 rounded-none border-solid border-dark-5
                          border-l-0 border-r-0 border-t-0 bg-transparent ${
                            news.length !== index + 1
                              ? "border-b"
                              : "border-b-0"
                          }`}
              component={Link}
              href={`/column/news/${content.id}`}
            >
              <Text className="text-sm">
                {dayjs(content.publishedAt).format("YYYY-MM-DD")}
              </Text>
              <Text className="text-sm font-bold">{content.title}</Text>
            </Card>
          ))
        : Array.from({ length: 3 }, (_, index) => (
            <Card
              key={index}
              className="h-[113px] md:h-[52px] flex flex-col md:flex-row gap-6"
            >
              <Skeleton className="w-20 h-4" />
              <Skeleton className="w-full h-4" />
            </Card>
          ))}
    </Container>
  );
}

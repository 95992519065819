/* eslint-disable react/jsx-pascal-case */
import Head from "next/head";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { GetServerSideProps } from "next";
import { KeyVisual } from "@/components/Top/KeyVisual";
import { PopularTickets } from "@/components/Top/PopularTickets";
import { HowToOrder } from "@/components/Top/HowToOrder";
import { FAQ } from "@/components/Top/FAQ";
import { Layout } from "@/components/Layout";
import { Box, Title, Transition, rem } from "@mantine/core";
import { useWindowScroll } from "@mantine/hooks";
import { useDeviceQuery } from "@/utils";
import { CTAButton } from "@/components/CTAButton";
import { useState } from "react";
import { ROUTES } from "@/constants/routes";
import { About } from "@/components/Top/About";
import { SectionItem } from "@/components/SectionItem";
import { AreaRouteFooter } from "@/components/Top/AreaRouteFooter";
import { ClaspoWidget } from "@/components/Top/ClaspoWidget";
import { CustomNotice } from "@/components/CustomNotice";
import { GroupService } from "@/components/Top/GroupService";
import { GmEsimAppBanner } from "@/components/Top/GroupService/GmEsimAppBanner";
import { ColumnNews } from "@/components/Top/ColumnNews";
import { microCmsClient } from "@/api";
import { IContent } from "@/types";

export default function App({ news }: { news: IContent[] }): JSX.Element {
  const { t } = useTranslation();
  const [scroll, scrollTo] = useWindowScroll();
  const [isMobile] = useDeviceQuery();

  const [searchRef, setSearchRef] =
    useState<React.RefObject<HTMLDivElement> | null>(null);

  const renderRoutes = ROUTES.filter((route) => route.popular === true);

  return (
    <>
      <Head>
        <title>{t("meta:home.title")}</title>
        <meta content={t("meta:home.description")} name="description" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <link href="/favicon.ico" rel="icon" />
      </Head>
      <Layout>
        <CustomNotice />
        <KeyVisual
          title={
            <Title order={1} size={rem(28)} lh={rem(38)} c="blue.9">
              {t("top:kv.title")}
            </Title>
          }
          setSearchRef={setSearchRef}
          pcImg="/kv.webp"
          spImg="/kv_sp.webp"
        />
        <SectionItem
          title={t("column:categories.news")}
          className="!pb-0"
          titleUrlLabel={t("common:see_more")}
          titleUrl="/column"
          titleClass="uppercase"
        >
          <ColumnNews news={news} />
        </SectionItem>
        <SectionItem title={t("top:sections.popular_tickets.title")}>
          <PopularTickets routes={renderRoutes} hasImg />
        </SectionItem>
        <GmEsimAppBanner />
        <SectionItem
          title={t("top:sections.boarding.title")}
          className="bg-dark-3"
          titleUrl={t("top:sections.boarding.link")}
          titleClass="uppercase"
        >
          <HowToOrder />
        </SectionItem>
        <FAQ />
        <About
          title={t("top:sections.faq.info.title")}
          description={t("top:sections.faq.info.description", {
            joinArrays: "\n\n",
          })}
        />
        <SectionItem
          title={t("top:group_services.title")}
          className="bg-dark-3"
          bg="dark.1"
        >
          <GroupService />
        </SectionItem>
        <SectionItem
          title={t("area:common.links.title")}
          className="bg-dark-3"
          bg="dark.1"
        >
          <AreaRouteFooter />
        </SectionItem>
        <ClaspoWidget />
      </Layout>
      <Transition
        mounted={isMobile! && scroll.y >= 755}
        transition="slide-up"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Box
            pos="sticky"
            className={`z-[100] border-solid border-t border-dark-5 border-l-0
                          border-r-0 border-b-0`}
            bottom={0}
            w="100%"
            px={rem(15)}
            py={rem(10)}
            bg="white"
            style={styles}
          >
            <CTAButton
              text={t("common:search.cta")}
              onClick={() =>
                scrollTo({
                  y: searchRef?.current?.offsetTop! - 80,
                })
              }
            />
          </Box>
        )}
      </Transition>
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  const translations = await serverSideTranslations(locale ?? "en", [
    "meta",
    "common",
    "top",
    "faq",
    "locations",
    "area",
    "column",
  ]);

  const news = await microCmsClient.getList<IContent>({
    endpoint: "column",
    queries: {
      fields: "id,title,publishedAt",
      filters: "category[contains]news",
      limit: 3,
    },
  });

  return {
    props: {
      ...translations,
      news: news.contents,
    },
  };
};
